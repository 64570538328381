.steps-content {
  min-height: 200px;
  margin-top: 16px;
  padding-top: 20px;
  text-align: center;
  /* background-color: #fafafa;
  border: 1px dashed #e9e9e9; */
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-icon-dot {
  background: #b32a2a !important;
}

.ant-steps-item-tail::after {
  background: #b32a2a !important;
}

.ant-collapse-content-box {
  padding: 4px 4px 4px 4px !important;
}

.ant-collapse-header {
  padding: 4px 4px 4px 36px !important;
}

.ant-collapse-arrow {
  padding-top: 4px !important;
}

.input-disabled {
  background-color: #fafafa;
  color: #000;
}
